(function (window) {
  // 'use strict';
  function initTracker() {
    var trackerObj = {
      snowplowEnabled: false, //flag to denote if snowplow tracker was enabled for this instance
      tdEnabled: false, //flag to denote if td tracker was enabled for this instance
      collectorHost: "", //snowplow collector host
      appId: "", //snowplow app id
      td: null, //td instance
      lob: "", //line of business for td tracking
      domain: "", //domain for td tracking

      /*** **************************************************************************/
      /*** ************ Public methods - exposed for the container ************ *****/
      /*** **************************************************************************/

      initConfig(obj) {
        if (obj.config && obj.config.snowplow) {
          this.snowplowEnabled = true;
          const aid = obj.config.snowplow.appId;
          const chost = obj.config.snowplow.cDomain;

          var cname = chost;
          if (chost) {
            if (chost === 'com-informa-prod1.mini.snplow.net') {
              cname = window.location.hostname;
            }
            else {
              cname = chost.substring(2, chost.length);
            }
            fetchCDWhiteList(cname);
          }

          if (aid && chost) {
            this.initSnowplowConfig(aid, chost);
          } else {
            console.err("appId and cDomain are required.");
          }
        }

        let lastUrl = location.href;

        if ('MutationObserver' in window) {
          new MutationObserver(() => {
            const url = location.href;
            if (url !== lastUrl) {
              lastUrl = url;
              window.snowplow("trackPageView");
            }
          }).observe(document, { subtree: true, childList: true });
        }

        if (obj.config && obj.config.td) {
          this.tdEnabled = true;
          const lob = obj.config.td.lob;
          const domain = obj.config.td.domain;
          if (lob && domain) {
            this.initTDConfig(lob, domain);
          } else {
            console.err("lob and domain are required.");
          }
        }

        //Capture eloqua hash email from GET parameters
        this.setEloquaHashEmail();
      },



      setTrackingUID(userId) {
        if (this.snowplowEnabled) {
          if (userId) {
            /* document.cookie = "IRIS_ID=" + userId + "; path=/;"; */
            this.setDomainCookie('IRIS_ID', userId);
          }
          window.snowplow("setUserIdFromCookie", "IRIS_ID");
        }

        if (this.tdEnabled) {
          this.setTDCustomData(userId);
        }
      },

      clearTrackingUID() {
        if (this.snowplowEnabled) {
          let domainName = this.collectorHost;
          if (domainName && domainName.startsWith('c.')) {
            domainName = domainName.substring(1, domainName.length);
            document.cookie = "IRIS_ID=; Max-Age=-99999999; path=/; domain=" + domainName + ";";
          }
          else {
            document.cookie = "IRIS_ID=; Max-Age=-99999999; path=/;";
          }
          window.snowplow("clearUserData");
        }

        //clear eloqua email
        /* document.cookie = "_iris_eloqua_em=; Max-Age=-99999999; path=/;";*/
      },

      trackImpression(data) {
        if (this.snowplowEnabled) {
          window.snowplow("trackSelfDescribingEvent", {
            event: {
              schema:
                "iglu:com.snowplowanalytics.snowplow/ad_impression/jsonschema/1-0-0",
              data: data,
            },
          });
        }
      },

      trackStructEvt(data) {
        if (this.snowplowEnabled) {
          if (data) {
            window.snowplow("trackStructEvent", {
              category: data.cat,
              action: data.act,
              label: data.lbl,
              property: data.prop,
              value: data.val,
            });
          }
        }
      },

      setDomainCookie(name, val) {
        let domainName = this.collectorHost;
        if (domainName && domainName.startsWith('c.')) {
          domainName = domainName.substring(1, domainName.length);
          document.cookie = name + "=" + val + "; path=/; domain=" + domainName + ";";
        }
        else {
          document.cookie = name + "=" + val + "; path=/;";
        }
      },

      handleCrossDomainLink(evt) {
        let rawUrl = evt.detail.url;
        let tgtUrl = new URL(evt.detail.url);
        let tgtHost = tgtUrl.hostname;
        let tgtPath = tgtUrl.pathname;
        let tgtSearch = tgtUrl.search;
        let duidCookie = getSnowplowDuid();
        const crossDomainDests = getCrossDomainList();
        if (allowRestrictedLinking(tgtUrl, crossDomainDests)) {
          if (!isSameDomainLink(rawUrl)) {
            if (!qStrContainsSpParam(rawUrl) && isQueryStringValid(tgtSearch)) {
              if (duidCookie) {
                if (!tgtSearch.includes('_sp')) {
                  if (tgtSearch.includes('?')) {
                    tgtSearch = tgtSearch + '&_sp=' + duidCookie;
                  }
                  else {
                    tgtSearch = tgtSearch + '?_sp=' + duidCookie;
                  }
                }
              }
            }
          }
        }

        let tgt = '_blank';
        if(evt.detail.mode && evt.detail.mode == 'same'){
          tgt = '_self';
        }

        window.open('https://' + tgtHost + tgtPath + tgtSearch, tgt);
      },

      handleHrefCDLink(linkElement) {
        const crossDomainSites = ['www.batterytechonline.com', 'www.thebatteryshow.com'];
        let cdUrl = new URL(linkElement.href);
        cdUrlSearch = cdUrl.search;
        let rawUrl = linkElement.href;
        let tgtHost = cdUrl.hostname;
        let tgtPath = cdUrl.pathname;
        const crossDomainDests = getCrossDomainList();

        if (allowRestrictedLinking(linkElement, crossDomainDests)) {
          /* 1. Check if the target url is not from the same domain */
          if (!isSameDomainLink(linkElement.href)) {
            /* 2. Check if href value is url only */
            if ((linkElement.href.includes('javascript:')) ||
              (linkElement.href.includes('tel:')) ||
              (linkElement.href.includes('mailto:'))) {
              return false;
            }
            /* 3. Check if the URL has a valid query string */
            /* 4. Check if the query string has _sp param */
            else if (!isQueryStringValid(cdUrlSearch) || qStrContainsSpParam(cdUrl.href)) {
              return false;
            }
            else {
              if (!(linkElement.href.indexOf('#') > 0)) {
                let duidCookie = getSnowplowDuid();

                if (duidCookie) {
                  if (linkElement.search.includes('?')) {
                    linkElement.search = linkElement.search + '&_sp=' + duidCookie;
                  }
                  else {
                    linkElement.search = linkElement.search + '?_sp=' + duidCookie;
                  }

                  linkElement.href = 'https://' + tgtHost + tgtPath + linkElement.search;
                  return true;
                }
                else {
                  return false;
                }
              }
              else {
                return false;
              }
            }
          }
        }
      },


      /*** **************************************************************************/
      /*** ************ Private methods - Snowplow tracker ******* ************ *****/
      /*** **************************************************************************/
      setEloquaHashEmail() {
        const params = new Proxy(new URLSearchParams(window.location.search), {
          get: (searchParams, prop) => searchParams.get(prop)
        });
        let value = params.sp_eh;
        if (value) {
          /*document.cookie = "_iris_eloqua_em=" + value + "; path=/;";*/
          this.setDomainCookie('_iris_eloqua_em', value);
        }
      },

      initSnowplowConfig(appId, collectorHost) {
        includeSnowplowScripts();

        window.snowplow("newTracker", "sp1", collectorHost, {
          appId: appId,
          discoverRootDomain: true,
          cookieSameSite: "Lax",
          postPath: '/com.iiris/ed0',
          crossDomainLinker: this.handleHrefCDLink,
          contexts: {
            performanceTiming: false,
          }
        });

        window.snowplow("setUserIdFromCookie", "IRIS_ID");

        var me = this;
        window.addEventListener("crossDomainClick", function (evt) {
          me.handleCrossDomainLink(evt);
        });

        window.snowplow("enableActivityTracking", {
          minimumVisitLength: 30,
          heartbeatDelay: 30,
        });

        window.snowplow("enableLinkClickTracking");
        window.snowplow("trackPageView");

        this.collectorHost = collectorHost;
        this.appId = appId;

        // Separated duid as a cookie
        var spduid = getSnowplowDuid();
        if (spduid) {
          /* document.cookie = "_iris_duid=" + spduid + "; path=/;";*/
          this.setDomainCookie('_iris_duid', spduid);
        }
      },

      /*** **************************************************************************/
      /*** *********************   Private methods - TD tracker *************** *****/
      /*** **************************************************************************/

      initTDConfig(lob, domain) {

        includeTDScripts();

        const write_apikey = process.env.TD_WRITE_KEY;

        /* Treasure Object contains DB , write key and host details
        Sample DB naming convention webtracking_<lob> */
        this.td = new Treasure({
          database: "webtracking_" + lob,
          writeKey: write_apikey,
          host: "eu01.in.treasuredata.com",
        });

        this.td.setSignedMode();
        this.td.set("$global", "td_global_id", "td_global_id");

        var successCallback = function (td_global_id) { };

        var errorCallback = function (err) {
          console.log(err);
        };

        var afterPageviewCallback = function () {
          this.td.fetchGlobalID(successCallback, errorCallback);
        };

        this.lob = lob;
        this.domain = domain;
        this.setTDCustomData(getCookie('IRIS_ID'));

        this.td.trackPageview("js_pageviews_" + lob + "_" + domain, afterPageviewCallback);
      },

      setTDCustomData(uid) {
        var customdata = {};
        try {
          if (typeof uid !== "undefined") {
            customdata.userid = uid;
          }
        } catch (error) {
          console.log("Error occurred in setTDCustomData ::" + uid)
          return customdata;
        }
        console.log("Setting :: " + customdata.userid);
        this.td.set("js_pageviews_" + this.lob + "_" + this.domain, customdata);
      },
    };

    return trackerObj;
  }

  function includeSnowplowScripts() {
    (function (p, l, o, w, i, n, g) {
      if (!p[i]) {
        p.GlobalSnowplowNamespace = p.GlobalSnowplowNamespace || [];
        p.GlobalSnowplowNamespace.push(i);
        p[i] = function () {
          (p[i].q = p[i].q || []).push(arguments);
        };
        p[i].q = p[i].q || [];
        n = l.createElement(o);
        g = l.getElementsByTagName(o)[0];
        n.async = 1;
        n.src = w;
        g.parentNode.insertBefore(n, g);
      }
    })(
      window,
      document,
      "script",
      "//static.iris.informa.com/f23io39d.js",
      "snowplow"
    );
  }

  function includeTDScripts() {
    /* this first line is the libarary needed for Treasure Data JS Tag 
      TD library is downloaded from //cdn.treasuredata.com/sdk/3.0/td.min.js and version 3.0 is the latest. this library version should be changed in the future */

    !(function (t, e) {
      if (void 0 === e[t]) {
        (e[t] = function () {
          e[t].clients.push(this),
            (this._init = [Array.prototype.slice.call(arguments)]);
        }),
          (e[t].clients = []);
        for (
          var r = [
            "addRecord",
            "blockEvents",
            "fetchServerCookie",
            "fetchGlobalID",
            "fetchUserSegments",
            "resetUUID",
            "ready",
            "setSignedMode",
            "setAnonymousMode",
            "set",
            "trackEvent",
            "trackPageview",
            "trackClicks",
            "unblockEvents",
          ],
          s = 0;
          s < r.length;
          s++
        ) {
          var c = r[s];
          e[t].prototype[c] = (function (t) {
            return function () {
              return (
                (this["_" + t] = this["_" + t] || []),
                this["_" + t].push(Array.prototype.slice.call(arguments)),
                this
              );
            };
          })(c);
        }
        var n = document.createElement("script");
        (n.type = "text/javascript"),
          (n.async = !0),
          (n.src =
            ("https:" === document.location.protocol ? "https:" : "http:") +
            "//cdn.treasuredata.com/sdk/3.0/td.min.js");
        var o = document.getElementsByTagName("script")[0];
        o.parentNode.insertBefore(n, o);
      }
    })("Treasure", this);
  }

  function isQueryStringValid(qStr) {
    let valid1 = true;
    let valid2 = true;

    if (!(qStr === '')) {
      if (qStr.includes('?') && !qStr.includes('=')) {
        valid1 = false;
      }

      /* if (!(/^\?([\w-]+(=[\w-]+)*(&[\w-]+(=[\w-]+)+)*)*$/.test(qStr))) {
        valid2 = false;;
      }*/
    }
    return (valid1 && valid2);
  }

  function qStrContainsSpParam(cdUrl) {
    if (cdUrl.includes('?_sp=') || cdUrl.includes('&_sp=')) {
      return true;
    }
    else
      return false;
  }

  function isSameDomainLink(urlStr) {
    let u = new URL(urlStr);
    return (window.location.hostname == u.hostname);
  }


  function getCookie(name) {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
  }

  function getSnowplowDuid(cookieName) {
    cookieName = cookieName || '_sp_';
    var matcher = new RegExp(cookieName + 'id\\.[a-f0-9]+=([^;]+);?');
    var match = document.cookie.match(matcher);
    if (match && match[1]) {
      return match[1].split('.')[0];
    } else {
      return false;
    }
  }

  /* Extracts _iris_cdl cookie and decodes the CD hosts */
  function getCrossDomainList() {
    var crossDomainDests = [];
    try {
      var cdl_cookie = getCookie('_iris_cdl');
      var encCDDests = cdl_cookie ? cdl_cookie.split(',') : [];
      if (encCDDests && encCDDests.length > 0) {
        encCDDests.forEach(dest => {
          crossDomainDests.push(atob(dest));
        });
      }
    } catch (e) {
      console.log(e)
    }
    return crossDomainDests;
  }

  function allowRestrictedLinking(linkElement, crossDomainDests) {
    var lnkUrl = new URL(linkElement.href);
    for (var i=0; i< crossDomainDests.length; i++) {
      var lnk = crossDomainDests[i];
      var d = lnk.substring(1, lnk.length);
      if (lnk.startsWith('*.') && lnkUrl.hostname.endsWith(d)) {
        return true;
      }
      else if (lnkUrl.hostname == lnk || lnkUrl.hostname == 'www.'+lnk) {
        return true;
      }
    }
    return false;
  }

  /* Fetches the list of whitelisted domains for CDL and creates _iris_cdl cookie */
  function fetchCDWhiteList(domainName) {
    if (!getCookie('_iris_cdl')) {
      var cdl_cfg_url = 'https://static.iris.informa.com/widgets/config/cdl/' + btoa(domainName) + '.json';
      try {
        fetch(cdl_cfg_url,).then(response => response.json())
          .then(response => {
            var now = new Date();
            now.setTime(now.getTime() + (24 * 1) * 3600 * 1000);
            document.cookie = "_iris_cdl=" + response + "; path=/; expires=" + now.toUTCString() + ";";
          }).catch(function () {
            console.log('CDL is not configured for ::' + domainName);
          });
      } catch (err) {
        console.log('CDL is not configured for ::' + domainName);
      }
    }
  }

  if (typeof window.IIRISTracker === "undefined") {
    window.IIRISTracker = initTracker();
    var event = new Event("trackerInitialized");
    window.dispatchEvent(event);
  }
})(window);
